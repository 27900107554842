import { ConfigStickyCta } from '@supersonic/config';

const starRating: ConfigStickyCta = {
  enabled: true,
  backgroundColor: 'sand',
  backgroundColorHover: 'sand_darker1',
  textColor: 'white',
  borderRadiusLevel: '_1',
  links: {
    holidays: {
      icon: 'Sunrise',
      name: 'Holidays',
      url: '/en-gb/holidays/',
    },
    flights: {
      icon: 'Airplane',
      name: 'Flights',
      url: '/en-gb/flights/',
    },
    carHire: {
      icon: 'Car',
      name: 'Car Hire',
      url: '/en-gb/car-hire/',
    },
  },
};
export default starRating;
