import { ConfigTravelInsurance } from '@supersonic/config';

const travelInsurance: ConfigTravelInsurance = {
  title: 'Cover from just £2.28*',
  description:
    '*Based on an individual aged 30 with no pre-existing medical conditions taking single trip cover for 3 days to France. Cover starts on January 11th, 2025 and ends on January 14th, 2025. Price correct as of January 14th, 2025.',
  ctaText: 'Get a new quote',
  ctaUrl: 'https://travelinsurance.travelsupermarket.com/',
};

export default travelInsurance;
